export const PRIMARY = '#2e3092';
export const PRIMARY_TEXT = '#000';
export const SECONDARY = '#818181';
export const SECONDARY_TEXT = '#C7C7C7';

export const GRAY_PRIMARY = '#DEDEDE';
export const GRAY_SECONDARY = '#B4B4B4';
export const GRAY_THIRD = '#6E6E6E';
export const GRAY_LIGHT = '#F8F8F8';
export const GRAY_BACKGROUND = '#F1F1F1';
export const GRAY_BACKGROUND_2 = '#F2F2F2';
export const WHITE = '#FFFFFF';
export const WHITE_IMPORTANT = '#FFFFFF !important';

export const RED = '#f32e3b';
export const ORANGE = '#FE5D26';
export const ORANGE_FLASHSALE = '#FF0002';
export const GREEN = '#46954D';
export const BLACK = '#000000';
export const YELLOW_BACKGROUND = '#fffbbb';

export const GREEN_SWITCH = '#59F89A';
export const RED_SWITCH = '#F47171';

export const BLUE_LIGHT = '#2E7BBF';
